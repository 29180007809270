import { Button, Space, Table , Switch} from 'antd'
import Search from 'antd/lib/input/Search'
import React, { useEffect, useState } from 'react'
import ClinicsInfoModal from '../config/ClinicsInfoModal'
import { clinicsinfo } from '../config/clinicsonfo'

const Clinics = () => {
  const [clinicData, setClinicData] = useState([])
  const [totalElements, setTotalElements] = useState(0)
  const [loading, setLoading] = useState(true)
  const [current, setCurrent] = useState(1)
  const [visible, setvisible] = useState(false)
  const handleOnchange = (p) => {
    setCurrent(p)
  }
  const batafsilBtn = (id,obj) => {
    setvisible(true)
  }
  function onChange(checked) {
    console.log(`switch to ${checked}`);
  }
  const clinicsinfocolumn = [
    {
      title: "Nomi",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Client discount",
      dataIndex: "clientDiscount",
      key: "clientDiscount"
    },
    {
      title: "Total discount",
      dataIndex: "totalDiscount",
      key: "totalDiscount"
    },
    {
      title: "Batafsil ma'lumotlar",
      dataIndex: "id",
      key: "id",
      render: (id, obj) => <Button onClick={() => batafsilBtn(id, obj)} type="primary">Batafsil</Button>
    },
    {
      title: 'Aloqalarni uzish',
      dataIndex: "id",
      key: "id",
      render: (id) => <Switch defaultChecked onChange={() => onChange(id)} />
    }
  ]
  useEffect(() => {
    setClinicData(clinicsinfo)
    setTotalElements(clinicsinfo.length)
    setLoading(false)
  }, [])
  const oncancel = () =>{
    setvisible(false)
  }
  return (
    <div>
      <Table
        title={() => (
          <Space>
            <Search
              key={1}
              placeholder="Поиск"
              onSearch={(value) => console.log(value)}
              style={{ width: 200 }}
            />
          </Space>
        )}
        dataSource={clinicData}
        columns={clinicsinfocolumn}
        pagination={{
          current: current,
          pageSize: 10,
          onChange: handleOnchange,
          total: totalElements,
          showTotal: (totalElements) => `ВСЕ: ${totalElements}`
        }}
        loading={loading}
        scroll={{ x: 800 }}
      />
      <ClinicsInfoModal visible={visible} oncanCel={oncancel} />
    </div>
  )
}

export default Clinics
