import { axiosInstance } from "../host";

export const getTable = () => {
	const config = {
		method: "GET",
		url: 'owner/order',
	};
	return axiosInstance(config);
};

export const getTableByStatus = (status = "ACTIVE") => {
	const config = {
		method: "GET",
		url: `owner/order?status=${status}`,
	};
	return axiosInstance(config);
};

