import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { TOKEN } from "./assets/constants";
import LayOut from "./comps/Layout";
import ProtectedRoute from "./comps/ProtectedRoute";
import { routes } from "./data/routes";
import { getCookie } from "./functions/useCookies";
import LoginP from "./components/LoginP";

function App() {
  const token = getCookie(TOKEN);

  return (
    <Router>

    <Switch>
      <LayOut>
        {
          routes.map(value => (
              <Route key={Math.random()} path={value.route} component={value.page}/>
          ))
        }
      </LayOut>


      {/*<Route*/}
      {/*  key={Math.random()}*/}
      {/*  exact*/}
      {/*  path="/"*/}
      {/*  render={() => {*/}
      {/*    return token ? (*/}
      {/*      <Redirect to="/customers" />*/}
      {/*    ) : (*/}
      {/*      <Redirect to="/login" />*/}
      {/*    );*/}
      {/*  }}*/}
      {/*/>*/}
      {/*<Route*/}
      {/*    key={Math.random()}*/}
      {/*    exact*/}
      {/*    path="/"*/}
      {/*    render={() => {*/}
      {/*      return <Redirect to="/customers" />*/}
      {/*    }}*/}
      {/*/>*/}
      {/*<Route exact path="/login" component={LoginP} />*/}
      {/*  {routes.map((item, index) => (*/}
      {/*    <ProtectedRoute*/}
      {/*      key={index}*/}
      {/*      path={item.route}*/}
      {/*      exact*/}
      {/*      component={item.page}*/}
      {/*    />*/}
      {/*  ))}*/}
    </Switch>
  </Router>
  );
};


export default App;
