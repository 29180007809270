import { DatePicker, Modal, Row, Space, Statistic, Table } from 'antd'
import React, { useState, useEffect } from 'react'
import { transactions } from './transactions'

const ClinicsInfoModal = ({ visible, oncanCel }) => {
  const [alldata, setAllData] = useState([])
  const [totalElements, setTotalElements] = useState(0)
  const [current, setCurrent] = useState(1)
  const [loading, setLoading] = useState(true)
  const handlePaginationChange = (p) => {
    setCurrent(p)
  }
  
  const infoscolumn = [
    {
      title: "Total",
      dataIndex: "total",
      key: "total"
    },
    {
      title: "OwnerProperty",
      dataIndex: "ownerProperty",
      key: "ownerProperty"
    },
    {
      title: "ClinicProperty",
      dataIndex: "clinicProperty",
      key: "clinicProperty"
    },
    {
      title:"Sana",
      dataIndex:"date",
      key:"date"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <Statistic value={status} valueStyle={status === "o'tdi" ? { color: "yellow" } : { color: "red" }} />
    },
    
  ]
  useEffect(() => {
    setAllData(transactions)
    setTotalElements(transactions.length)
    setLoading(false)
  }, [])
  return (
    <div>
      <Modal
        width={1000}
        title="Klinika ma'lumotlari"
        centered
        visible={visible}
        onCancel={oncanCel}>
        <Space direction="vertical" size="large" wrap={true} style={{ width: '100%' }} >
          <Row gutter={[16, 16]}>
            <DatePicker mode="month" style={{ width: 200 }} />
          </Row>
          <Table
            bordered
            columns={infoscolumn}
            dataSource={alldata}
            pagination={{
              current: current,
              total: totalElements,
              pageSize: 30,
              onChange: handlePaginationChange,
              showTotal: (totalElements) => `ВСЕ: ${totalElements}`
            }}
            loading={loading}
          />
        </Space>
      </Modal>
    </div>
  )
}

export default ClinicsInfoModal
