import React, { useState } from "react";
import { Drawer, Layout, Menu } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { routes } from "../data/routes";
const { Header, Sider, Content } = Layout;

const LayOut = props => {
	const [collapsed, setCollapsed] = useState(false);
	const [visible, setVisible] = useState(false);
	const [modal, setModal] = useState(false);
	const toggle = () => {
		modal ? setVisible(!visible) : setCollapsed(!collapsed);
	};

	return (
		<div>
			<Layout className="admin-layout">
				<Drawer
					title="Menular"
					placement="left"
					closable={true}
					onClose={toggle}
					visible={visible}
					getContainer={false}
					style={{ position: "absolute" }}>
					<Menus toggle={toggle} />
				</Drawer>
				<Sider
					trigger={null}
					collapsible
					collapsed={modal ? true : collapsed}
					breakpoint="md"
					collapsedWidth="60"
					onBreakpoint={broken => {
						setModal(broken);
					}}
					className="d-md-block d-none vh-100 left-menu">
					<div className="logo">{collapsed ? "" : " MedPayOwner"}</div>
					<Menus color="dark" />
				</Sider>
				<Layout className="site-layout">
					<Header className="site-layout-background" style={{ padding: 0 }}>
						{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
							className: "trigger",
							onClick: toggle,
						})}
					</Header>
					<Content
						className="site-layout-background"
						style={{
							margin: "24px 16px",
							padding: 24,
							minHeight: 280,
						}}>
						{props.children}
					</Content>
				</Layout>
			</Layout>
		</div>
	);
};

export default LayOut;
const Menus = props => {
	return (
		<Menu theme={props.color} mode="inline">
			{routes.map((item, index) => (
				<Menu.Item key={"" + index} icon={item.icon} onClick={props.toggle}>
					<Link to={item.route}>{item.name}</Link>
				</Menu.Item>
			))}
		</Menu>
	);
};
