export const transactions = [
  {
    id:Math.random(),
    total:"$10000",
    ownerProperty:"$9000",
    clinicProperty:"$1000",
    status:"o'tdi",
    date:"09-10-2021"
  },
  {
    id:Math.random(),
    total:"$18000",
    ownerProperty:"$16200",
    clinicProperty:"$1800",
    status:"o'tdi",
    date:"10-10-2021"
  },
  {
    id:Math.random(),
    total:"$20000",
    ownerProperty:"$18000",
    clinicProperty:"$2000",
    status:"o'tmadi",
    date:"11-10-2021"
  },
  {
    id:Math.random(),
    total:"$12000",
    ownerProperty:"$10800",
    clinicProperty:"$1200",
    status:"o'tmadi",
    date:"12-10-2021"
  },
]