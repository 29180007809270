import React, { useEffect, useState } from 'react';
import { Table, Input, Select, Button, DatePicker } from 'antd';
import { getTable, getTableByStatus } from '../config/control';

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const filterColumns = [
	'clinicname',
	'servicename',
	'price',
	'timetable_enum',
	'transaction_id',
	'vendor_commission',
	'user_commission',
	'payment_type',
	'org_bank',
	'billing_transaction_id',
	'created',
];

const ControlTable = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		getData();
	}, []);

	const columns = [
		{
			title: 'Clinic',
			dataIndex: 'clinicname',
			key: 'clinicname',
		},
		{
			title: 'Service',
			dataIndex: 'servicename',
			key: 'servicename',
		},
		{
			title: 'Price',
			dataIndex: 'price',
			key: 'id',
		},
		{
			title: 'Status',
			dataIndex: 'timetable_enum',
			key: 'timetable_enum',
			filters: [
				{
					text: 'All Orders',
					value: '',
				},
				{
					text: 'ORDERED',
					value: 'ORDERED',
				},
				{
					text: 'PRE_ORDERED',
					value: 'PRE_ORDERED',
				},
				{
					text: 'RESERVED',
					value: 'RESERVED',
				},
				{
					text: 'APPROVED',
					value: 'APPROVED',
				},
				{
					text: 'REGISTERED',
					value: 'REGISTERED',
				},
				{
					text: 'REJECTED',
					value: 'REJECTED',
				},
				{
					text: 'NOT_ANSWERED',
					value: 'NOT_ANSWERED',
				},
				{
					text: 'TIME_FINISHED',
					value: 'TIME_FINISHED',
				},
				{
					text: 'CODE_REGISTERED',
					value: 'CODE_REGISTERED',
				},
				{
					text: 'NOT_PAID',
					value: 'NOT_PAID',
				},
				{
					text: 'RETURN_RECEIVED',
					value: 'RETURN_RECEIVED',
				},
				{
					text: 'RETURN_CONFIRMED',
					value: 'RETURN_CONFIRMED',
				},
			],
			filterDropdown: (props) => (
				<Select placeholder='Select a status' onChange={filterChange}>
					{props.filters.map((el) => (
						<Option key={el.value} value={el.value}>
							{el.text}
						</Option>
					))}
				</Select>
			),
		},
		{
			title: 'Transaction_id',
			dataIndex: 'transaction_id',
			key: 'transaction_id',
		},
		{
			title: 'Vendor commission',
			dataIndex: 'vendor_commission',
			key: 'id',
		},
		{
			title: 'User commission',
			dataIndex: 'user_commission',
			key: 'id',
		},
		{
			title: 'Payment type',
			dataIndex: 'payment_type',
			key: 'id',
		},
		{
			title: 'Bank',
			dataIndex: 'org_bank',
			key: 'id',
		},
		{
			title: 'Billing_id',
			dataIndex: 'billing_transaction_id',
			key: 'billing_transaction_id',
		},
		{
			title: 'Date',
			dataIndex: 'created_at',
			key: 'created_at',
			render: (v = '') => v.slice(0, 10),
			filterDropdown: ({ confirm }) => <RangePicker onChange={(dates) => pickerChange(dates, confirm)} />,
		},
	];

	const pickerChange = async (dates, confirm) => {
		confirm();
		if (dates) {
			let [picker1, picker2] = [dates[0]._d, dates[1]._d];
			picker1.setHours(0, 0, 0);
			picker2.setHours(0, 0, 0);
			const d = await getData(true);
			const filtered = d.filter((item) => {
				if (item.created_at) {
					let date = new Date(item.created_at);
					if (new Date(picker1) <= date && date <= new Date(picker2)) {
						return true;
					}
				}
				return false;
			});
			setData(filtered);
		}
	};

	const filterChange = (e) => {
		getTableByStatus(e).then(({ data }) => {
			if (data) {
				setData(data);
			}
		});
	};

	const getData = async (status = false) => {
		setLoading(true);
		return await getTable().then(({ data }) => {
			if (data && !status) {
				setData(data);
			}
			setLoading(false);
			return data ? data : [];
		});
	};

	const onSearch = async (val) => {
		if (!val) return;
		const filtered = [];
		const filterData = await getData(true);
		for (let i = 0; i < filterData.length; i++) {
			for (let j = 0; j < filterColumns.length; j++) {
				let obj_val = filterData[i][filterColumns[j]];
				if (typeof obj_val === 'number') {
					obj_val = obj_val.toString();
				}
				if (obj_val && obj_val.toLowerCase().includes(val.toLowerCase())) {
					filtered.push(filterData[i]);
					break;
				}
			}
		}
		setData(filtered);
	};

	const title = () => {
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
				}}>
				<Button style={{ marginRight: '10px' }} onClick={() => getData()} type='primary'>
					All orders
				</Button>
				<Search style={{ width: '250px' }} placeholder='input search text' onSearch={onSearch} enterButton />
			</div>
		);
	};

	return (
		<div>
			<Table
				loading={loading}
				title={title}
				rowKey={(obj) => obj.id}
				bordered
				dataSource={data}
				columns={columns}
				scroll={{ x: true }}
				pagination={{
					hideOnSinglePage: true,
				}}
			/>
		</div>
	);
};

export default ControlTable;
