export const clinicsinfo = [
  {
    id:Math.random(),
    name:"Zilol shifo",
    clientDiscount:"10%",
    totalDiscount:"15%"
  },
  {
    id:Math.random(),
    name:"NavoiyMed",
    clientDiscount:"10%",
    totalDiscount:"15%"
  },
  {
    id:Math.random(),
    name:"AkfaMedLine",
    clientDiscount:"10%",
    totalDiscount:"15%"
  },
  {
    id:Math.random(),
    name:"ToshMedicalCenter",
    clientDiscount:"10%",
    totalDiscount:"15%"
  }
]