import React from 'react';
import { Line } from '@ant-design/charts';
import {Tabs} from 'antd'

const CustomerChart = () => {

  const data=[
    {
      day:'11.05.2021',
      Mijozlar: 5,
      category: 'Analiz'
    },
    {
      day:'11.05.2021',
      Mijozlar: 6,
      category: 'Diagnoz'
    },
    {
      day:'11.05.2021',
      Mijozlar: 1,
      category: 'Lecheniya'
    },
    {
      day:'11.05.2021',
      Mijozlar: 3,
      category: 'Konsultatsiya'
    },
    {
      day:'17.05.2021',
      Mijozlar: 10,
      category: 'Analiz'
    },
    {
      day:'17.05.2021',
      Mijozlar: 5,
      category: 'Diagnoz'
    },
    {
      day:'17.05.2021',
      Mijozlar: 4,
      category: 'Lecheniya'
    },
    {
      day:'17.05.2021',
      Mijozlar: 6,
      category: 'Konsultatsiya'
    },
    {
      day:'24.05.2021',
      Mijozlar: 15,
      category: 'Analiz'
    },
    {
      day:'24.05.2021',
      Mijozlar: 11,
      category: 'Diagnoz'
    },
    {
      day:'24.05.2021',
      Mijozlar: 7,
      category: 'Lecheniya'
    },
    {
      day:'24.05.2021',
      Mijozlar: 9,
      category: 'Konsultatsiya'
    },
    {
      day:'01.06.2021',
      Mijozlar: 16,
      category: 'Analiz'
    },
    {
      day:'01.06.2021',
      Mijozlar: 9,
      category: 'Diagnoz'
    },
    {
      day:'01.06.2021',
      Mijozlar: 10,
      category: 'Lecheniya'
    },
    {
      day:'01.06.2021',
      Mijozlar: 5,
      category: 'Konsultatsiya'
    },
    {
      day:'08.06.2021',
      Mijozlar: 18,
      category: 'Analiz'
    },
    {
      day:'08.06.2021',
      Mijozlar: 13,
      category: 'Diagnoz'
    },
    {
      day:'08.06.2021',
      Mijozlar: 12,
      category: 'Lecheniya'
    },
    {
      day:'08.06.2021',
      Mijozlar: 14,
      category: 'Konsultatsiya'
    },
    {
      day:'15.06.2021',
      Mijozlar: 9,
      category: 'Analiz'
    },
    {
      day:'15.06.2021',
      Mijozlar: 11,
      category: 'Diagnoz'
    },
    {
      day:'15.06.2021',
      Mijozlar: 2,
      category: 'Lecheniya'
    },
    {
      day:'15.06.2021',
      Mijozlar: 8,
      category: 'Konsultatsiya'
    },
  ]

  const data2=[
    {
      day:'11.05.2021',
      Mijozlar: 1,
      category: 'Analiz'
    },
    {
      day:'11.05.2021',
      Mijozlar: 2,
      category: 'Diagnoz'
    },
    {
      day:'11.05.2021',
      Mijozlar: 1,
      category: 'Lecheniya'
    },
    {
      day:'11.05.2021',
      Mijozlar: 3,
      category: 'Konsultatsiya'
    },
    {
      day:'17.05.2021',
      Mijozlar: 2,
      category: 'Analiz'
    },
    {
      day:'17.05.2021',
      Mijozlar: 6,
      category: 'Diagnoz'
    },
    {
      day:'17.05.2021',
      Mijozlar: 4,
      category: 'Lecheniya'
    },
    {
      day:'17.05.2021',
      Mijozlar: 1,
      category: 'Konsultatsiya'
    },
    {
      day:'24.05.2021',
      Mijozlar: 5,
      category: 'Analiz'
    },
    {
      day:'24.05.2021',
      Mijozlar: 1,
      category: 'Diagnoz'
    },
    {
      day:'24.05.2021',
      Mijozlar: 7,
      category: 'Lecheniya'
    },
    {
      day:'24.05.2021',
      Mijozlar: 9,
      category: 'Konsultatsiya'
    },
    {
      day:'01.06.2021',
      Mijozlar: 6,
      category: 'Analiz'
    },
    {
      day:'01.06.2021',
      Mijozlar: 9,
      category: 'Diagnoz'
    },
    {
      day:'01.06.2021',
      Mijozlar: 0,
      category: 'Lecheniya'
    },
    {
      day:'01.06.2021',
      Mijozlar: 5,
      category: 'Konsultatsiya'
    },
    {
      day:'08.06.2021',
      Mijozlar: 8,
      category: 'Analiz'
    },
    {
      day:'08.06.2021',
      Mijozlar: 3,
      category: 'Diagnoz'
    },
    {
      day:'08.06.2021',
      Mijozlar: 2,
      category: 'Lecheniya'
    },
    {
      day:'08.06.2021',
      Mijozlar: 4,
      category: 'Konsultatsiya'
    },
    {
      day:'15.06.2021',
      Mijozlar: 9,
      category: 'Analiz'
    },
    {
      day:'15.06.2021',
      Mijozlar: 1,
      category: 'Diagnoz'
    },
    {
      day:'15.06.2021',
      Mijozlar: 2,
      category: 'Lecheniya'
    },
    {
      day:'15.06.2021',
      Mijozlar: 4,
      category: 'Konsultatsiya'
    },
  ]

  const data3=[
    {
      day:'11.05.2021',
      Tushum: 1000,
      category: 'Analiz'
    },
    {
      day:'11.05.2021',
      Tushum: 2000,
      category: 'Diagnoz'
    },
    {
      day:'11.05.2021',
      Tushum: 1000,
      category: 'Lecheniya'
    },
    {
      day:'11.05.2021',
      Tushum: 3000,
      category: 'Konsultatsiya'
    },
    {
      day:'17.05.2021',
      Tushum: 2000,
      category: 'Analiz'
    },
    {
      day:'17.05.2021',
      Tushum: 6000,
      category: 'Diagnoz'
    },
    {
      day:'17.05.2021',
      Tushum: 4000,
      category: 'Lecheniya'
    },
    {
      day:'17.05.2021',
      Tushum: 1000,
      category: 'Konsultatsiya'
    },
    {
      day:'24.05.2021',
      Tushum: 5000,
      category: 'Analiz'
    },
    {
      day:'24.05.2021',
      Tushum: 1000,
      category: 'Diagnoz'
    },
    {
      day:'24.05.2021',
      Tushum: 7000,
      category: 'Lecheniya'
    },
    {
      day:'24.05.2021',
      Tushum: 9000,
      category: 'Konsultatsiya'
    },
    {
      day:'01.06.2021',
      Tushum: 6000,
      category: 'Analiz'
    },
    {
      day:'01.06.2021',
      Tushum: 9000,
      category: 'Diagnoz'
    },
    {
      day:'01.06.2021',
      Tushum: 10000,
      category: 'Lecheniya'
    },
    {
      day:'01.06.2021',
      Tushum: 5000,
      category: 'Konsultatsiya'
    },
    {
      day:'08.06.2021',
      Tushum: 8000,
      category: 'Analiz'
    },
    {
      day:'08.06.2021',
      Tushum: 3000,
      category: 'Diagnoz'
    },
    {
      day:'08.06.2021',
      Tushum: 2000,
      category: 'Lecheniya'
    },
    {
      day:'08.06.2021',
      Tushum: 4000,
      category: 'Konsultatsiya'
    },
    {
      day:'15.06.2021',
      Tushum: 9000,
      category: 'Analiz'
    },
    {
      day:'15.06.2021',
      Tushum: 1000,
      category: 'Diagnoz'
    },
    {
      day:'15.06.2021',
      Tushum: 2000,
      category: 'Lecheniya'
    },
    {
      day:'15.06.2021',
      Tushum: 4000,
      category: 'Konsultatsiya'
    },
  ]
 
  var config = {
    data: data,
    xField: 'day',
    yField: 'Mijozlar',
    seriesField: 'category',
    yAxis: {
      label: {
        formatter: function formatter(v) {
          return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
            return ''.concat(s, ',');
          });
        },
      },
    },
  };
  var config2 = {
    data: data2,
    xField: 'day',
    yField: 'Mijozlar',
    seriesField: 'category',
    yAxis: {
      label: {
        formatter: function formatter(v) {
          return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
            return ''.concat(s, ',');
          });
        },
      },
    },
  };
  var config3 = {
    data: data3,
    xField: 'day',
    yField: 'Tushum',
    seriesField: 'category',
    yAxis: {
      label: {
        formatter: function formatter(v) {
          return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
            return ''.concat(s, ',');
          });
        },
      },
    },
  };

 const data4=[
      {
        month: 'Iyun, 2020',
        customers: 0
      },  
      {
        month: 'Iyul, 2020',
        customers: 30
      },  
      {
        month: 'Avgust, 2020',
        customers: 40
      },  
      {
        month: 'Sentabr, 2020',
        customers: 50
      },  
      {
        month: 'Oktabr, 2020',
        customers: 70
      },  
      {
        month: 'Noyabr, 2020',
        customers: 90
      },  
      {
        month: 'Dekabr, 2020',
        customers: 130
      },  
  ]

  var config4 = {
    data: data4,
    xField: 'month',
    yField: 'customers',
    seriesField: 'category',
    yAxis: {
      label: {
        formatter: function formatter(v) {
          return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
            return ''.concat(s, ',');
          });
        },
      },
    },
  };

  const { TabPane } = Tabs;
    return (
        <div>
            <Tabs type="card">
              <TabPane tab="Haftalik jami mijozlar" key="1">
                 <Line {...config} />
              </TabPane>
              <TabPane tab="Haftalik yangi mijozlar" key="2">
                  <Line {...config2} />
              </TabPane>
              <TabPane tab="Tushgan tushum" key="3">
                   <Line {...config3} />
              </TabPane>
              <TabPane tab="Jami mijozlar" key="4">
                   <Line {...config4} />
              </TabPane>
            </Tabs>
        </div>
    );
};


export default CustomerChart;