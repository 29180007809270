import { ControlFilled, SisternodeOutlined, UserOutlined } from "@ant-design/icons";
import Clinics from "../components/Clinics";
import ControlTable from "../components/ControlTable";
import CustomerChart from "../components/CustomerChart";

export const routes = [
	{
		route: "/customers",
		name: `Customers`,
		page: CustomerChart,
		icon: <UserOutlined />,
	},
	{
		route: "/clinics",
		name: `Clinics`,
		page: Clinics,
		icon: <SisternodeOutlined />,
	},
	{
		route: "/orders",
		name: "Orders",
		page: ControlTable,
		icon: <ControlFilled />,
	},
];
