import React from "react";
import { Row, Col, Form, Input, Button, Typography, message, Avatar } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { setCookie } from "../functions/useCookies";
import { TOKEN } from "../assets/constants";
import { loginUser } from "../config/auth";
import "../login.css";
const { Title } = Typography;

const LoginP = () => {
	const [loginForm] = Form.useForm();

	const onFinish = values => {
		loginUser(values)
			.then(res => {
				if (res && res.status === 200 && res.data) {
					setCookie(TOKEN, res.data.access_token);
					message.success("Login muvaffaqiyatli yakunlandi");
					window.location.href = "/customers";
				} else {
					message.error("Ошибка логина или пароля! Пожалуйста, попробуйте еще раз :))", [0.5]);
					loginForm.resetFields();
				}
			})
			.catch(err => {
				window.location.href = "/dashboard";
			});
	};
	return (
		<div className="essential_container_login_page">
			<Row key="login_row" justify="center" align="middle" className="essential_row">
				<div className="login_avatar_div">
					<Avatar size={75} className="login_avatar" icon={<UserOutlined />} />
				</div>
				<Col xs={20} sm={12} md={16} lg={16}>
					<Title level={2} className="text-center login_title">
						Saytga kirish
					</Title>

					<Form name="basic" onFinish={onFinish}>
						<Form.Item
							name="username"
							rules={[
								{
									required: true,
									message: "Пожалуйста, введите ваш логин!",
								},
							]}
							key="username">
							<Input
								autoFocus
								prefix={<UserOutlined className="site-form-item-icon" id="usericon_login" />}
								placeholder="Пожалуйста, введите ваш логин"
								className="login_input"
							/>
						</Form.Item>
						<Form.Item
							name="password"
							rules={[
								{
									required: true,
									message: "Пожалуйста, введите пароль!",
								},
							]}
							key="password">
							<Input.Password
								prefix={<LockOutlined className="site-form-item-icon" id="lockicon_login" />}
								placeholder="Пожалуйста, введите пароль"
								className="login_input"
							/>
						</Form.Item>
						<Form.Item key="sumbitbtn">
							<Button
								type="primary"
								htmlType="submit"
								style={{
									borderRadius: "11px",
									padding: "18px 22px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									fontSize: "20px",
									lineHeight: "22px",
									fontWeight: "800",
									boxShadow: "2px 2px 10px rgb(120,100,100)",
									backgroundColor: "#4998af",
									width: "80%",
									margin: "0 auto",
								}}>
								Вход
							</Button>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</div>
	);
};

export default LoginP;
